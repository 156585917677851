/*

---
title: Filterable Table Module
name: filterable-table-module
category: Javascript
---

*/

define( 'app/filterable-tables',[ 'app/utils' ] , function( UTILS )
{
	// --------------------------------------------------

	var FILTERABLE = function( settings )
	{
		// Abandon if no table element supplied
		if( !settings.$table ) return false;

		// Keyboard input debounce delay
		this.debounce_delay = 200;
		
		// Assign our table element
		this.$table = settings.$table;

		// Set a unique id for the <table>
		this.$table.id = 'filterable_' + Date.now() + '#' + Math.ceil( Math.random() * 100000 );
		
		// We'll keep our active filters here
		this.filters = {};
		
		// Load up our filterable fields
		this.fields = this.read_fields();
		
		// Retrieve data from the table
		this.data = this.read_data();

		// Abandon if there's only one thing (or less?)
		if( this.data.length <= 1 ) return false;

		// console.log( 'Fields: ------------------------- ' , this.fields );
		// console.log( 'Data: ------------------------- ' , this.data );

		// Let AT know that the table could change
		this.$table.setAttribute( 'aria-live' , 'polite' );

		// Create/insert the filter form
		this.$form = this.render_form();

		// No results message
		this.no_results_message = settings.no_results_message ? settings.no_results_message : '<p><i>Sorry, there are no results matching your filters.</i></p>';

		// No results placeholder
		this.$no_results = document.createElement( 'div' );
		this.$no_results.innerHTML = this.no_results_message;

		// Make a key for storing the filters in sessionStorage
		this.storage_key = 'filters__' + window.location.href;

		// Check the URL for preset filters in query args or session
		this.initial_state();

		return this.$table;
	};

	// --------------------------------------------------
	// 

	FILTERABLE.prototype.initial_state = function()
	{
		var query_args = new URLSearchParams( window.location.search );
		var session_filters = this.saved_filters();

		for( var f = 0 ; f < this.fields.length ; f++ )
		{
			var field = this.fields[ f ];

			// Get an initial value from the query args first...
			var value = query_args.get( field.id + '_filter' );

			// ...but then override it with the session filter if present
			if( typeof session_filters[ field.id ] != 'undefined' ) value = session_filters[ field.id ];
			
			if( value != null )
			{
				switch( field.type )
				{
					case 'select' :
						if( field.$control.querySelector( 'option[value="' + value + '"]' ) )
						{
							field.$control.querySelector( 'select' ).value = value;
							this.update( field.$control );
						}
						break;

					case 'radio' :
						if( field.$control.querySelector( 'input[type=radio][value="' + value + '"]' ) )
						{
							field.$control.querySelector( 'input[type=radio][value="' + value + '"]' ).checked = true;
							this.update( field.$control );
						}
						break;

					case 'checkbox' :
						if( field.$control.querySelector( 'input[type=checkbox][value="' + value + '"]' ) )
						{
							field.$control.querySelector( 'input[type=checkbox]' ).checked = true;
							this.update( field.$control );
						}
						break;
						
					default : // text
						field.$control.querySelector( 'input[type=text]' ).value = value;
						this.update( field.$control );
						break;
				}
			}
		}
	};

	// --------------------------------------------------
	// Load the filterable table data

	FILTERABLE.prototype.read_data = function()
	{
		// Check the container element type
		if( this.$table.nodeName == 'TABLE' )
		{
			return this.read_data_from_table();
		}
		else
		{
			return this.read_data_from_other();
		}
	};

	// --------------------------------------------------
	// Load the filterable data from list items

	FILTERABLE.prototype.read_data_from_other = function()
	{
		// Returnable
		var data = [];

		// Get direct descendants of the container - these are our list items
		var $rows = this.$table.querySelectorAll( ':scope > *' );

		// Process each row
		for( r = 0 ; r < $rows.length ; r ++ )
		{
			datum = JSON.parse( $rows[ r ].getAttribute( 'data-values' ) );
			datum.$row = $rows[ r ];

			data.push( datum );
		}

		return data;
	};

	// --------------------------------------------------
	// Load the filterable data from table rows

	FILTERABLE.prototype.read_data_from_table = function()
	{
		// Returnable
		var data = [];

		var $rows = this.$table.querySelectorAll( 'tbody > tr' );

		// Process each row
		for( r = 0 ; r < $rows.length ; r ++ )
		{
			var $cells = $rows[ r ].querySelectorAll( 'td' );
			
			var row_data = {};
			
			// Process each cell
			for( c = 0 ; c < $cells.length ; c ++ )
			{
				// Skip if not a filterable field index
				if( typeof( this.fields[ c ] ) !== 'object' ) continue;
				
				// Get the this field's column index
				var column_index = this.fields[ c ].id;
				
				// Look up the right table cell
				var $cell = $cells[ column_index ];
				
				// Get data-value attribute; fall back to cell content
				var raw_value = $cell.getAttribute( 'data-value' ) ? $cell.getAttribute( 'data-value' ) : $cell.innerHTML;

				// Split into multiple values or an empty array
				var values = raw_value != '' ? raw_value.split( '|' ).map( function( single_value ){ return single_value.trim(); } ) : [];
				
				row_data[ column_index ] = values;
			}
			
			data.push(
			{
				$row: $rows[ r ],
				values: row_data,
			}); 
		}
		
		return data;
	};
	
	// --------------------------------------------------
	// Load up fields 

	FILTERABLE.prototype.read_fields = function()
	{
		if( this.$table.nodeName == 'TABLE' )
		{
			return this.read_fields_from_table();
		}
		else
		{
			return this.read_fields_from_other();
		}
	};
	
	// --------------------------------------------------
	// Load up fields from the container's data attribute

	FILTERABLE.prototype.read_fields_from_other = function()
	{
		var fields = JSON.parse( this.$table.getAttribute( 'data-fields' ) );

		return fields.fields || [];
	};
	
	// --------------------------------------------------
	// Load up fields from the table headings

	FILTERABLE.prototype.read_fields_from_table = function()
	{
		// Get the first bunch of <th> elements we find
		var $header_cells = this.$table.querySelectorAll( 'thead > tr > th' );

		// Returnable
		var fields = [];
		
		// Process each <th>
		for( i = 0 ; i < $header_cells.length ; i ++ )
		{
			// console.log( 'Header: ------------------------- ' , $header_cells[ i ] );

			var $header_cell = $header_cells[ i ];
			
			// Skip if this is not a filterable column
			if( !$header_cell.matches( '[ data-filterable ]' ) ) continue;

			// Data cells in this column will have a matching index
			fields.push(
			{
				id: i,

				// Get a name if supplied, fall back to HTML content
				name: $header_cell.getAttribute( 'data-name' ) ? $header_cell.getAttribute( 'data-name' ) : $header_cell.innerHTML,

				// The type of filter control: [text|select|checkbox|radio]
				type: $header_cell.getAttribute( 'data-type' ),

				// Modifier for the input: [default|large|small]
				modifier: $header_cell.getAttribute( 'data-modifier' ) ? $header_cell.getAttribute( 'data-modifier' ) : 'default',
			});
		}
		
		return fields;
	};
	
	// --------------------------------------------------

	FILTERABLE.prototype.render_form = function()
	{
		// The form itself
		var $form = document.createElement( 'form' );
		$form.setAttribute( 'class' , 'c-form c-form--faint' );
		$form.setAttribute( 'action' , '#'+this.$table.id );
		$form.setAttribute( 'method' , 'get' );
	
		// Field wrapper
		var $fieldset = document.createElement( 'fieldset' );
		$fieldset.setAttribute( 'class' , 'fieldset_inline' );
		$form.appendChild( $fieldset );
	
		// Fieldset text label
		var $legend = document.createElement( 'legend' );
		$legend.setAttribute( 'class' , 'sr-only' );
		$legend.innerHTML = 'Filters';
		$fieldset.appendChild( $legend );

		// Individual controls
		for( var i = 0 ; i < this.fields.length ; i ++ )
		{
			var control_id = this.$table.id + '__' + i;
			var unique_values = this.unique_values( this.fields[ i ] );
			var has_blank_values = this.has_blank_values( this.fields[ i ] )

			// If we only have a single value then don't bother
			if( unique_values.length + ( has_blank_values ? 1 : 0 ) < 2 )
			{
				continue;
			}
			
			// Control wrapper
			var $control = document.createElement( 'div' );
			$control.setAttribute( 'class' , 'c-form__element fieldset_item--' + ( this.fields[ i ].modifier || 'default' ) + '' );
			$control.setAttribute( 'data-fieldindex' , i );
			$fieldset.appendChild( $control );
			
			// Label
			var $label = document.createElement( 'label' );
			$label.setAttribute( 'class' , 'c-form__label' );
			$label.setAttribute( 'for' , control_id );
			$label.innerHTML = this.fields[ i ].name;
			// We'll add the label a bit later depending on the field type

			switch( this.fields[ i ].type )
			{
				case 'select' :

					// Label first
					$control.appendChild( $label );

					// Select wrapper
					var $select_wrapper = document.createElement( 'div' );
					$select_wrapper.setAttribute( 'class' , 'c-form__input c-form__input--select' );
					$control.appendChild( $select_wrapper );
					
					// Select itself
					var $select = document.createElement( 'select' );
					$select.setAttribute( 'id' , control_id );
					$select.setAttribute( 'class' , 'c-form__select' );
					$select_wrapper.appendChild( $select );
					
					// Add in the default option
					var $default_option = document.createElement( 'option' );
					$default_option.setAttribute( 'value' , '' );
					$default_option.innerHTML = 'Any';
					$select.appendChild( $default_option );

					// console.log( 'Rendering field: ------------------------- ' , this.fields[ i ] );

					// Add in the other options
					for( var o = 0 ; o < unique_values.length ; o ++ )
					{
						// Skip blank values
						if( unique_values[ o ] == '' ) continue;

						var $option = document.createElement( 'option' );
						$option.setAttribute( 'value' , unique_values[ o ] );
						$option.innerHTML = unique_values[ o ];
						$select.appendChild( $option );
					}
				
					// Add an event listener to refresh the table when this is changed
					$select.addEventListener( 'change' , this.input_change_handler( $control ).bind( this ) );
					
				break;
				
				case 'radio' :

					// Label first
					$control.appendChild( $label );

					// console.log( 'Rendering field: ------------------------- ' , this.fields[ i ] );

					// Create a group for the radio buttons
					var $button_group = document.createElement( 'div' );
					$button_group.classList.add( 'c-form__element-group' );

					// Add in the individual radio buttons
					for( var o = 0 ; o < unique_values.length ; o ++ )
					{
						// Skip blank values
						if( unique_values[ o ] == '' ) continue;

						var radio_button_id = 'radio_' + Date.now() + '#' + Math.ceil( Math.random() * 100000 )

						var $radio_button = document.createElement( 'input' );
						$radio_button.classList.add( 'c-form__radio' );
						$radio_button.setAttribute( 'name' , control_id + '[]' );
						$radio_button.setAttribute( 'type' , 'radio' );
						$radio_button.setAttribute( 'value' , unique_values[ o ] );
						$radio_button.setAttribute( 'id' , radio_button_id );

						var $radio_label = document.createElement( 'label' );
						$radio_label.classList.add( 'c-form__label' );
						$radio_label.setAttribute( 'for' , radio_button_id );
						$radio_label.innerHTML = unique_values[ o ];

						var $radio_wrapper = document.createElement( 'div' );
						$radio_wrapper.classList.add( 'c-form__radio-group' );

						$radio_wrapper.appendChild( $radio_button );
						$radio_wrapper.appendChild( $radio_label );

						$button_group.appendChild( $radio_wrapper );

						// Add an event listener to refresh the table when this is changed
						$radio_button.addEventListener( 'change' , this.input_change_handler( $control ).bind( this ) );
					}

					$control.appendChild( $button_group );

				break;

				case 'checkbox' :

					// This bumps the field content downwards
					$control.classList.add( 'fieldset_item--nolabel' );
					
					// The control itself
					var $checkbox = document.createElement( 'input' );
					$checkbox.setAttribute( 'id' , control_id );
					$checkbox.setAttribute( 'class' , 'c-form__checkbox' );
					$checkbox.setAttribute( 'type' , 'checkbox' );
					$checkbox.setAttribute( 'value' , 1 );
					$control.appendChild( $checkbox );
					
					// Add a space between the label and input
					$control.appendChild( document.createTextNode( ' ' ) );

					// Label after
					$control.appendChild( $label );

					// Add event listeners to refresh the table when this is changed
					$checkbox.addEventListener( 'change' , this.input_change_handler( $control ).bind( this ) );

				break;

				default : // text

					// Label first
					$control.appendChild( $label );

					// Input itself
					var $input = document.createElement( 'input' );
					$input.setAttribute( 'id' , control_id );
					$input.setAttribute( 'class' , 'c-form__input c-form__input--text' );
					$input.setAttribute( 'type' , 'text' );
					$control.appendChild( $input );
					
					// Add event listeners to refresh the table when this is changed
					$input.addEventListener( 'input' , this.input_change_handler( $control ).bind( this ) );

				break;
			}
			
			// Add the control to the fields
			this.fields[ i ].$control = $control;
		}
		
		// Add a submit button
		var $footer = document.createElement( 'div' );
		$footer.setAttribute( 'class' , 'c-form__element is-hidden' );
		$fieldset.appendChild( $footer );

		var $button = document.createElement( 'button' );
		$button.setAttribute( 'class' , '' );
		$button.setAttribute( 'type' , 'submit' );
		$button.innerHTML = "Submit";
		$footer.appendChild( $button );
		
		// Add an event listener to make sure the form isn't actually submitted if clicked
		$button.addEventListener( 'click' , function( e ){ e.preventDefault(); } );

		// Find out where to inject the form
		var $parent = this.$table.parentNode;
		var $before = this.$table;
		
		// If the parent is a wrapper for the table use its parent instead
		if( $parent.matches( '.c-table-scrollingwrapper' ) )
		{
			$before = $parent;
			$parent = $parent.parentNode;
		}

		// Inject our form
		$parent.insertBefore( $form , $before );
	};

	// --------------------------------------------------
	
	FILTERABLE.prototype.update = function( $control )
	{
		// Update filter state

		var field_index = $control.getAttribute( 'data-fieldindex' );

		// Get the filter value from the control

		var value = false;

		switch( this.fields[ field_index ].type )
		{
			case 'select' :
				value = $control.querySelector( '.c-form__select' ).value;
			break;

			case 'radio' :
				value = $control.querySelector( '.c-form__radio:checked' ).value;
			break;

			case 'checkbox' :
				if( $control.querySelector( '.c-form__checkbox:checked' ) )
				{
					value = $control.querySelector( '.c-form__checkbox:checked' ).value;
				}
			break;

			default : // text
				value = $control.querySelector( '.c-form__input--text' ).value;
			break;
		}		

		// Add/override or remove the value to filter
		if( typeof value != 'undefined' && value != '' )
		{
			this.filters[ field_index ] = value;
		}
		else
		{
			delete this.filters[ field_index ];
		}

		// Save the filters for this session
		this.save_filters();

		// console.log( 'Filters: ------------------------- ' , this.filters );

		// Keep a tally of how many rows are hidden
		var hidden_tally = 0;
		
		// Check each data row
		for( var r = 0 ; r < this.data.length ; r ++ )
		{
			var row = this.data[ r ];

			// Defaults to true, if any of the filters don't match we'll set it to false
			var row_matches = true;
			
			// Apply each filter in turn
			var filter_keys = this.filters ? Object.keys( this.filters ) : {};

			for( var f = 0 ; f < filter_keys.length ; f ++ )
			{
				// Clean up some key variables
				var filter_value = this.filters[ filter_keys[ f ] ].toLowerCase().trim().normalize( 'NFKD' ).replace( /[\u0300-\u036f]/g , '' );
				var field = this.fields[ filter_keys[ f ] ];
				var field_values = row.values[ field.id ];

				// Default to false, we'll set it to true if any of the filters match
				var filter_matches = false;

				// Check the filter against each value for the row
				for( var v = 0 ; v < field_values.length ; v ++ )
				{
					// Clean up the field value
					var field_value = String( field_values[ v ] ).toLowerCase().trim().normalize( 'NFKD' ).replace( /[\u0300-\u036f]/g , '' );
					
					switch( field.type )
					{
						case 'select' :
						case 'radio' :
						case 'checkbox' :

							if( field_value == filter_value )
							{
								filter_matches = true;
							}
							
							break;
							
						default : // text
						
							// Split into separate filter terms
							var filter_values = filter_value.split( ' ' );

							// All terms must match: assume true then check each term and...
							filter_matches = true;
							for( var i = 0 ; i < filter_values.length ; i ++ )
							{
								// ...switch to false if any don't
								filter_matches *= ( field_value.indexOf( filter_values[ i ] ) != -1 );
							}

						break;
					}
				}
				
				// If none of our filters matched this will set row_matches to false
				row_matches *= filter_matches;
			}

			// Hide/unhide the row
			if( row_matches )
			{
				row.$row.removeAttribute( 'hidden' );
			}
			else
			{
				row.$row.setAttribute( 'hidden' , true );
				hidden_tally++;
			}
		}
		
		// Check for empty status
		if( hidden_tally == this.data.length )
		{
			if( !this.$no_results.parentNode )
			{
				this.$table.parentNode.insertBefore( this.$no_results , this.$table.nextSibling );
			}
		}
		else
		{
			if( this.$no_results.parentNode )
			{
				this.$table.parentNode.removeChild( this.$no_results );
			}
		}

		// Flash on update
		if( typeof this.$table.classList != 'undefined' )
		{
			this.$table.classList.remove( 'u-flashin' );
			
			// Delay update by 2xRAF to ensure that the keyframe animation kicks in
			var $table = this.$table;
			requestAnimationFrame( function(){ requestAnimationFrame( function(){
				$table.classList.add( 'u-flashin' );
			} ); } );
		}

	};

	// --------------------------------------------------
	
	FILTERABLE.prototype.input_change_handler = function( $control )
	{
		// Abort if no control element is provided
		if( $control == undefined ) return false;
		
		// Debounce this so it doesn't fire while typing fast
		return UTILS.debounce( function( e )
		{
			this.update( $control );
		} , this.debounce_delay );
	};

	// --------------------------------------------------

	FILTERABLE.prototype.has_blank_values = function( field )
	{
		// Go over each row
		for( var r = 0 ; r < this.data.length ; r ++ )
		{
			// Grab the values for this row
			var row_values = this.data[ r ].values[ field.id ];

			// If there are none then yes - we have blank values
			if( row_values.length == 0 ) return true;
		}

		return false;
	};

	// --------------------------------------------------

	FILTERABLE.prototype.unique_values = function( field )
	{
		var unique_values = [];
			
		// Go over each row
		for( var r = 0 ; r < this.data.length ; r ++ )
		{
			// Grab the values for this row
			var row_values = this.data[ r ].values[ field.id ];

			// Check each value
			for( var v = 0 ; v < row_values.length ; v ++ )
			{
				var row_value = row_values[ v ];

				// Add the value if it's not already in our list of values
				if( unique_values.indexOf( row_value ) == -1 )
				{
					unique_values.push( row_value );
				}
			}
		}

		unique_values.sort();

		// console.log( 'Unique values: ------------------------- ' , unique_values );

		return unique_values;
	};

	// --------------------------------------------------
	// Restores filter state from session storage

	FILTERABLE.prototype.saved_filters = function()
	{
		try
		{
			return JSON.parse( sessionStorage.getItem( this.storage_key ) ) || {};
		}
		catch( error )
		{
			return {};
		}
	};

	// --------------------------------------------------
	// Saves the filter state to session storage

	FILTERABLE.prototype.save_filters = function()
	{
		try
		{
			// ⚠ this.filters is indexed by field index but we want it by field id
			
			var filters_by_id = {};

			var filter_indexes = Object.keys( this.filters );

			for( var f = 0 ; f < filter_indexes.length ; f++ )
			{
				var filter_index = filter_indexes[ f ];
				filters_by_id[ this.fields[ filter_index ].id ] = this.filters[ filter_index ];
			}

			sessionStorage.setItem( this.storage_key , JSON.stringify( filters_by_id ) );
		}
		catch( error ){}
	};

	// --------------------------------------------------
	
	return FILTERABLE;

});


/*

---
title: Clearing table module
name: clearing-table-module
category: Javascript
---

*/

define( 'app/clearing-vacancies',[ 'app/filterable-tables' ],
function( FILTERABLE )
{
	// Toggle this to control whether or not entry requirement info will be shown
	// (overrides the show_entry_requirements option)
	var disable_entry_requirements = true;

	var clearing_data_url = 'https://www.york.ac.uk/static/data/clearing/all-courses.json';
	// Need some different data to test with? Use this one 👇
	// var clearing_data_url = 'https://www.york.ac.uk/static/data/clearing/all-courses.DEMO.json';

	var no_results_message = '<div class="c-alert c-alert--info"><div class="c-alert__content"><p>There are no vacancies matching your criteria for September 2024. <a href="//www.york.ac.uk/study/undergraduate/courses/all">Explore your options for 2025 entry.</a></p></div></div>';
	var no_results_per_subject_message = '<div class="c-alert c-alert--info"><div class="c-alert__content"><p>There are no vacancies in this subject for September 2024. <a href="//www.york.ac.uk/study/undergraduate/courses/all">Explore your options for 2025 entry.</a></p></div></div>';

	var contextual_clearing_url = 'https://www.york.ac.uk/study/undergraduate/clearing/contextual/';

	var CLEARINGVACANCIES = function( options )
	{
		// No container? No listing!
		if( !options.container ) return false;

		// Show entry requirements?
		options.show_entry_requirements = ( options.show_entry_requirements && !disable_entry_requirements );

		fetch( clearing_data_url )
			.then( function( response )
			{
				if( response.status !== 200 ) throw Error( 'Could not load clearing data from ' + url );
				return response.json();
			})
			.then( function( clearing_data )
			{
				// Filter by subject if provided
				return clearing_data.filter( function( course )
				{
					if( options.subject && course[ 'Subject' ].split( '|' ).indexOf( options.subject ) === -1 ) return false;
					return true;
				});
			})
			.then( function( clearing_data )
			{
				return clearing_data.sort(function( a , b ){ return ( a[ 'Title of course' ] > b[ 'Title of course' ] ? 1 : -1 ) } );
			})
			.then( function( clearing_data )
			{
				return make_vacancies_listing( clearing_data , options.container , options.show_entry_requirements , options.subject );
			})
			.catch( function( error )
			{
				console.error( error );
			});
	};

	function make_vacancies_listing( clearing_data , $container , show_entry_requirements , subject )
	{
		// Clean up clearing flags
		clearing_data = clearing_data.map( function( course ) { return cleanup_clearing_fields( course ); } );

		// Filter out courses that aren't in clearing
		clearing_data = clearing_data.filter( function( course )
		{
			return (
				course[ 'Home' ] ||
				course[ 'Contextual' ] ||
				course[ 'International' ]
			);
		});

		// If there are no courses to display then abandon now
		if( clearing_data.length == 0 )
		{
			$container.empty();
			$container.append( subject ? no_results_per_subject_message : no_results_message );
			return;
		}

		// Prepare the field/filter data

		var fields =
		{
			fields:
			[
				{
					id: 'i_am_a',
					name: 'I am a...',
					type: 'radio',
					modifier: 'large',
				},
				{
					id: 'name_etc',
					name: 'Search by course name or UCAS code',
					type: 'text',
					modifier: '',
				},
			],
		};

		// Add a subject filter only if we're not already pre-filtering by subject
		if( !subject )
		{
			fields.fields.push(
			{
				id: 'subject',
				name: 'Subject',
				type: 'select',
				modifier: '',
			});
		}

		// Make the listing container

		var $course_list = document.createElement( 'div' );
		$course_list.classList.add( 'c-clearing-table' );
		$course_list.setAttribute( 'data-fields' , JSON.stringify( fields ) );

		// Make and add the listing items

		for( var i = 0 ; i < clearing_data.length ; i++ )
		{
			var course = clearing_data[ i ];

			// Add it to the listing
			$course_list.append( make_course( course , show_entry_requirements ) );
		}

		// Inject it all into the container element
		$container.empty();
		$container.append( $course_list );

		// Turn it into a filterable thingy
		return new FILTERABLE( { $table: $course_list , no_results_message: no_results_message } );
	}

	// Converts those pesky Y/Ns to booleans
	function cleanup_clearing_fields( course )
	{
		var clearing_fields =
		[
			'Home',
			'Contextual',
			'International',
		];

		clearing_fields.forEach( function( field )
		{
			if( typeof course[ field ] === 'undefined' )
			{
				course[ field ] = false;
			}
			else
			{
				course[ field ] = ( course[ field ].toLowerCase() === 'y' );
			}
		});

		return course;
	}

	function make_course( course , show_entry_requirements )
	{
		// Who does this course have places for?

		var i_am_a = [];

		// Show for "I am a UK student" if either vacanices for home _or_ contextual is ticked
		if( course[ 'Home' ] || course[ 'Contextual' ] ) i_am_a.push( 'Home (UK) student' );

		if( course[ 'International' ] ) i_am_a.push( 'International student' );

		// Put the data together...

		var course_values =
		{
			values:
			{
				name_etc: [ course[ 'Title of course' ] + ' / ' + course[ 'Qualification earned' ] + ' / ' + course[ 'UCAS code' ] + ( typeof course[ 'Hidden keywords' ] !== 'undefined' ? ' / ' + course[ 'Hidden keywords' ] : '' ) ],
				subject: course[ 'Subject' ].split( '|' ),
				i_am_a: i_am_a,
			},
		};

		// Make the element

		var $course_item = document.createElement( 'div' );
		$course_item.setAttribute( 'data-values' , JSON.stringify( course_values ) );
		$course_item.classList.add( 'c-clearing-table__course' );

		var $course_title = document.createElement( 'p' );
		$course_title.classList.add( 'c-clearing-table__title' );
		$course_item.append( $course_title );

		var $course_link = document.createElement( 'a' );
		$course_link.setAttribute( 'href' , course[ 'Link to course page' ] );
		$course_link.innerHTML = course[ 'Qualification earned' ] + ' ' + course[ 'Title of course' ];
		$course_title.append( $course_link );

		// Add a "Places for ..." label

		var note = 'Places for: ';

		// Only show 'Contextual' label if we're not showing the 'Home' label
		if( course[ 'Home' ] ) note += "<span data-context=\"home\" class=\"c-clearing-table__course-tag\"><span>Home (UK) students</span></span> ";
		else if( course[ 'Contextual' ] ) note += "<span data-context=\"contextual\" class=\"c-clearing-table__course-tag\"><span>Home (UK) students eligible for <a href=\"" + contextual_clearing_url + "\">contextual clearing</a></span></span> ";

		if( course[ 'International' ] ) note += "<span data-context=\"international\" class=\"c-clearing-table__course-tag\"><span>International students</span></span> ";

		$course_title.innerHTML += '<br>' + note;

		// Add extra bullet points + entry requirements?

		if( show_entry_requirements )
		{
			var bullet_points = [];

			if( typeof course[ 'No grades' ] !== 'undefined' && course[ 'No grades' ] !== '' )
			{
				bullet_points.push( '<li class="c-clearing-table__entry-requirements">' + course[ 'No grades' ] + '</li>' );
			}
			else if( typeof course[ 'Entry requirements' ] !== 'undefined' && course[ 'Entry requirements' ] !== '' )
			{
				var entry_requirements = '';

				entry_requirements += '<li class="c-clearing-table__entry-requirements"><strong>' + course[ 'Entry requirements' ] + '</strong> or equivalent tariff points from three A levels.';

				if( course[ 'Bullet 1' ] || course[ 'Bullet 2' ] || course[ 'Bullet 3' ] )
				{
					entry_requirements += '    <br>';
					entry_requirements += '    <small class="c-clearing-table__bullets">Must include: ';
				}

				if( course[ 'Bullet 1' ] ) entry_requirements += course[ 'Bullet 1' ];
				if( course[ 'Bullet 2' ] ) entry_requirements += '; ' + course[ 'Bullet 2' ];
				if( course[ 'Bullet 3' ] ) entry_requirements += '; ' + course[ 'Bullet 3' ] + '';
				if( course[ 'Bullet 1' ] || course[ 'Bullet 2' ] || course[ 'Bullet 3' ]) entry_requirements += '</small>';

				entry_requirements += '</li>';

				bullet_points.push( entry_requirements );
			}
			else if( typeof course[ 'Alternative qualification' ] !== 'undefined' && course[ 'Alternative qualification' ] !== '' && course[ 'Alternative requirement' ] !== '' )
			{
				var alternative_qualification = '';
				
				alternative_qualification += '<li class="c-clearing-table__entry-requirements"><strong>' + course[ 'Alternative qualification' ] + '</strong> ' + course[ 'Alternative requirement' ];

				if( course[ 'Bullet 1' ] || course[ 'Bullet 2' ] || course[ 'Bullet 3' ] )
				{
					alternative_qualification += '    <br>';
					alternative_qualification += '    <small class="c-clearing-table__bullets">';
				}

				if( course[ 'Bullet 1' ] ) alternative_qualification += course[ 'Bullet 1' ];
				if( course[ 'Bullet 2' ] ) alternative_qualification += '; ' + course[ 'Bullet 2' ];
				if( course[ 'Bullet 3' ] ) alternative_qualification += '; ' + course[ 'Bullet 3' ] + '';
				if( course[ 'Bullet 1' ] || course[ 'Bullet 2' ] || course[ 'Bullet 3' ] ) alternative_qualification += '</small>';

				alternative_qualification += '</li>';

				bullet_points.push( alternative_qualification );
			}

			// DISABLED as per C&C working group request of 24/07/2024
			// Cf: https://uoy-communications.slack.com/archives/C07DHPMNKLL/p1721819185953539
			// Add in the International Baccalaureate independently of the above
			// if( course[ 'International Baccalaureate' ] !== '' )
			// {
			// 	bullet_points.push( '<li class="c-clearing-table__entry-requirements"><strong>International Baccalaureate:</strong> ' + course['International Baccalaureate'] + '</li>' );
			// }

			bullet_points.push( '<li class="c-clearing-table__entry-requirements">Various UK and international qualifications are accepted. Call our Clearing line to discuss your grades.</li>' );

			// If we have any bullet points to show add them now

			if( bullet_points.length > 1 )
			{
				$course_item.innerHTML += '<ul class="u-two-columns">' + bullet_points.join( '' ) + '</ul>';
			}
			else if( bullet_points.length == 1 )
			{
				$course_item.innerHTML += '<ul style="padding-bottom:1px;">' + bullet_points.join( '' ) + '</ul>';
			}

		}

		// Done!
		return $course_item;
	}

	return CLEARINGVACANCIES;
});

